import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
import ContactForm from '../../../components/forms/contact-form'
import Anchor from "../../../components/ui/anchor";
import Button from "../../../components/ui/button";
import { useStaticQuery, graphql } from "gatsby"
import { MdPhone, MdEmail } from "react-icons/md";
import {
  ContactFormWrapper,
  LeftBox,
  ContactInfoBox,
} from "./contact-form-area.style";

const ContactFormArea = ({
    headingStyle,
    subHeadingStyle,
    textStyle,
  descStyle,
  conactInfoStyles: { titleStyle, phoneAnchorStyle, btnStyle },
}) => {
    const contactQueryData = useStaticQuery(graphql`
      query ContactQuery {
        sectionData: indexInfotechnoJson(
          id: { eq: "infotechno-contact-content" }
        ) {
          title
          desc
        }
        sectionBg: file(relativePath: { eq: "images/bg/contact-bg.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 520, quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        contactData: site {
          siteMetadata {
            contact {
              phone
              email
              emailText
            }
          }
        }
      }
    `);
    const { title, desc } = contactQueryData.sectionData;
    const imageData = contactQueryData.sectionBg.childImageSharp.fluid;
    const {
      phone,
      email,
      emailText,
    } = contactQueryData.contactData.siteMetadata.contact;
  return (
    <ContactFormWrapper>
      <Container>
        <Row alignitems="center">
          <Col lg={6}>
            <LeftBox>
              <Heading {...headingStyle}>
                To make requests for <br /> further information, <br />{" "}
                <span>contact us</span> via our social channels.
              </Heading>
              <Text {...textStyle}>
                We just need a couple of hours! No more than 2 working days
                since receiving your request.
              </Text>
            </LeftBox>
          </Col>
          <Col lg={6}>
            {/* <ContactForm/> */}
            <ContactInfoBox>
              <MdEmail className="icon" />
              <Heading {...titleStyle}>REACH OUT NOW!</Heading>
              {email && (
                <Heading {...subHeadingStyle}>
                <Anchor path={`mailto:${email}`} {...phoneAnchorStyle}>
                    {emailText}
                  </Anchor>
                </Heading>
              )}
              {/* <Button {...btnStyle}>Contact Us</Button> */}
            </ContactInfoBox>
          </Col>
        </Row>
      </Container>
    </ContactFormWrapper>
  );
};

ContactFormArea.propTypes = {
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object
}

ContactFormArea.defaultProps = {
    headingStyle: {
        as: "h3",
        position: "relative",
        pl: '34px',
        lineheight: 1.67,
        fontweight: 600,
        child: {
            color: 'primary'
        },
        layout: 'quote'
    },
    subHeadingStyle: {
        as: "h3",
        position: "relative",
        pl: '34px',
        lineheight: 1.67,
        fontweight: 600,
        child: {
            color: 'primary'
        },
    },
    textStyle: {
        mt: '15px',
        fontSize: '18px',
        ml: '34px',
        color: '#696969'
    },
    descStyle: {
        mt: '15px',
        fontSize: '18px',
        ml: '34px'
    },
    conactInfoStyles: {
        titleStyle: {
            as: 'h6',
            fontSize: '15px',
            letterspacing: '2px',
            texttransform: 'uppercase',
            mb: '10px',
            mt: '10px',
        },
        phoneAnchorStyle: {
            color: '#002fa6',
            hover: {
                layout: 2,
                color: '#002fa6'
            }
        },
        btnStyle: {
            mt: '20px',
            skin: 'secondary',
            minwidth: '230px',
            hover: {
                bgColor: 'primary'
            }
        }
    }
}

export default ContactFormArea;